import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import { Text, Box, Flex } from "rebass/styled-components";
import Fade from "react-reveal/Fade";
import { Link } from "gatsby";
import { Content, Title, Info, Date, BtnGroup, Btn } from "./style";
import { UL } from "../../../../styles/TCandPrivacyStyles";
import { Container } from "../../../global";

import Banner from "../../../../images/csr/sls.png";
import BtnIcon from "../../../../images/csr/btn.png";
import dataBuried from "../../../../utils/dataBuried";

const OutOfSchool = () => {
  const apply = () => {
    dataBuried("Website_Homepage_scholarship_apply_click", {
      status: "disabled",
    });
    // window.open("https://q6oisf77xb.feishu.cn/share/base/form/shrcnZ5FdYQCvJl31dVBhdWY9Kf");
  };
  return (
    <Container
      position="relative"
      style={{ width: "100vw", maxWidth: "100vw", padding: "0", background: "rgb(244, 252, 250);" }}
    >
      <Content>
        <Title>Scholarship</Title>
        <Info>
          The OPay University Scholarship Program aims to address the financial barriers hindering students from
          pursuing higher education. This initiative aligns with our commitment to academic excellence, diversity, and
          fostering a supportive learning environment. By providing financial assistance and promoting inclusivity, we
          aim to empower students and contribute to their academic and personal growth. The program seeks to enhance the
          educational experience of students by alleviating financial burdens, thus allowing them to focus on their
          studies and personal development.
        </Info>
        <Flex
          justifyContent="center"
          marginTop={["20px", "20px", "20px", "50px", "50px"]}
          flexDirection={["column", "column", "column", "row", "row"]}
        >
          <Box maxWidth={["100%", "100%", "100%", "60%", "60%"]}>
            <img src={Banner}></img>
          </Box>
        </Flex>
        <Title>BACKGROUND & PURPOSE</Title>
        <Info>
          Access to quality education is a cornerstone of individual and societal development. However, many students
          face financial challenges that impede their ability to pursue higher education. The OPay Scholarship Program
          is designed to bridge this gap and make education more accessible.
        </Info>
        <Info>The purpose of the scholarship program is to:</Info>
        <UL>
          <li style={{ marginBottom: "0px" }}>
            <Info>Provide financial assistance to students with demonstrated need.</Info>
          </li>
          <li style={{ marginBottom: "0px" }}>
            <Info>Promote inclusivity by ensuring equal opportunities for all.</Info>
          </li>
          <li style={{ marginBottom: "0px" }}>
            <Info>Enhance the employability and future success of scholarship recipients.</Info>
          </li>
        </UL>

        <Title>GOAL & OBJECTIVES</Title>
        <Info>
          <span>Goal:</span>
        </Info>
        <Info>
          To promote access to education, foster inclusivity, and contribute to the development of well-rounded
          individuals.
        </Info>
        <Info>
          <span>Objectives:</span>
        </Info>
        <Info>
          <span>1. Financial Assistance: </span>
          Provide financial support to students facing economic barriers to accessing higher education by reducing the
          financial burden of tuition, fees, and other educational expenses.
        </Info>
        <Info>
          <span>2. Promoting Inclusivity: </span>
          Ensure that individuals from all backgrounds have equal opportunities to access the benefits of the
          scholarship.
        </Info>
        <Info>
          <span>3. Career Development: </span>
          Support students in their career aspirations by providing the financial means to pursue internships, research
          opportunities, or other professional development activities. Enhance the employability and future success of
          scholarship recipients.
        </Info>
        <Title>ELIGIBILITY CRITERIA</Title>
        <Info>
          <span>1. Institution of Study: </span>
          Applications only open to undergraduate students of the Ahmadu Bello University, Zaria.
          <Date> Scholarship opportunities will soon be available in other public universities nationwide.</Date>
        </Info>
        <Info>
          <span>2. Course of Study: </span>
          Application is open to students from all departments of the Ahmadu Bello University, Zaria.
        </Info>
        <Info>
          <span>3. Level of Study: </span>
          Application is open to undergradute students of any level in the Ahmadu Bello University, Zaria.
        </Info>
        <Info>
          <span>4. Must have an active OPay account. </span>
        </Info>
        <Title>SCHOLARSHIP DETAILS</Title>
        <Info>1. Duration: One academic session.</Info>
        <Info>2. Scholarship Amount: ₦300,000.</Info>
        <Info>3. Scholarship Applications are open from Aug 8 - 28, 2024.</Info>
        <Info>
          4. 40 shortlisted undergraduate students will receive N300,000 per academic session upon successfully meeting
          the requirements and going through the screening process.
        </Info>
        <BtnGroup>
          <Btn onClick={() => apply()}>
            {/* <img src={BtnIcon}></img> */}
            <span>Application Closed</span>
          </Btn>
        </BtnGroup>
      </Content>
    </Container>
  );
};

export default OutOfSchool;
